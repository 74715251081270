<template>
  <div>
    <div v-if="history.length == 0">
      <alert>Não há histórico para esta cobrança.</alert>
    </div>
    <div v-else>
      <div class="show_on_pc">
        <div v-for="history_item in history" :key="history_item.id">
          <history-item :history_date="history_item.created_at">
            <title-box>{{ history_item.user_name | historyUserName }}</title-box>
            <span class="history_detail">{{ history_item.text }}</span>
          </history-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import { mapState, mapActions, mapMutations } from 'vuex';
import HistoryItem from '@/theme/components/HistoryItem.vue';
import TitleBox from '@/theme/boxes/TitleBoxInsideContainers.vue';
import Alert from '@/theme/components/Alert.vue';
import filters from '../filters';

export default {
  props: {
    charge_id: {
      type: [String, Number],
      default: '0',
    },
  },
  filters,
  components: {
    HistoryItem,
    TitleBox,
    Alert,
  },
  methods: {
    ...mapActions('histories', ['ActionListHistoryFilter']),
    ...mapMutations({
      setLinesPerPage: 'histories/SET_LINES_PER_PAGE',
    }),
    async load_history() {
      if (!this.charge_id) return;
      this.setLinesPerPage(50);
      this.ActionListHistoryFilter({ charge_id: this.charge_id });
    },
  },
  computed: {
    ...mapState('histories', ['history']),
  },
  watch: {
    charge_id: {
      async handler() {
        await this.load_history();
      },
    },
  },
  async mounted() {
    await this.load_history();
  },
};
</script>
<style type="text/css" scoped></style>
