<template>
  <div>
    <content-box icon="fa-dollar-sign" title="Detalhes da cobrança">
      <spinner :loading="processing" />
      <cancel-button cancel_text="Voltar" @cancel="goback" class="mb-20" />
      <div class="form-row">
        <div class="col-sm col-6">
          <stat-card title="Campanha" :value="charge.order_origin" />
        </div>
        <div class="col-sm col-6">
          <stat-card title="Produto" :value="charge.product_name" />
        </div>
        <div class="col-sm col-6">
          <stat-card title="Status" :value="$options.filters.charge_status(charge.charge_status)" />
        </div>
      </div>
      <title-box>Cliente</title-box>
      <div class="form-row">
        <div class="col-sm-6 col-12">
          <show-info-box title="Nome:">{{ charge.customer_name }}</show-info-box>
        </div>
        <div class="col-sm-6 col-12">
          <show-info-box title="Email:">{{ charge.customer_email }}</show-info-box>
        </div>
      </div>
      <div class="form-row">
        <div class="col-sm-6 col-12">
          <show-info-box title="Telefone:">{{
            charge.customer_phoneNumber | phoneNumber
          }}</show-info-box>
        </div>
        <div class="col-sm-6 col-12">
          <show-info-box title="CPF:">{{ charge.customer_cpf | cpf }}</show-info-box>
        </div>
      </div>
      <title-box>Cobrança</title-box>
      <div class="form-row" v-if="charge.charge_closed_status_date">
        <div class="col-sm-6 col-12">
          <show-info-box title="Encerramento:">{{
            charge.charge_closed_status_date
          }}</show-info-box>
        </div>
        <div class="col-sm-6 col-12">
          <show-info-box title="Encerrado por:">{{
            charge.charge_closed_status_user_id
          }}</show-info-box>
        </div>
      </div>
      <div class="form-row">
        <div class="col-sm-6 col-12">
          <show-info-box title="Origem:">{{ charge.charge_source | charge_origin }}</show-info-box>
        </div>
        <div class="col-sm-6 col-12">
          <show-info-box title="Plataforma:">{{
            charge.charge_platform | platform_logo
          }}</show-info-box>
        </div>
      </div>
      <div class="form-row">
        <div class="col-sm-6 col-12" v-if="show_payment_info">
          <show-info-box title="Forma de pagamento:">{{ charge.payment_method }}</show-info-box>
        </div>
        <div class="col-sm-6 col-12">
          <show-info-box title="Cadastrado em:">{{
            charge.created_at | datetime_brl
          }}</show-info-box>
        </div>
      </div>
      <div class="form-row" v-if="show_payment_info">
        <div class="col-sm-6 col-12">
          <show-info-box title="Link para pagamento:">{{
            charge.payment_checkout_link
          }}</show-info-box>
        </div>
        <div class="col-sm-6 col-12">
          <show-info-box title="Código de barras:">{{
            charge.payment_billet_barcode
          }}</show-info-box>
        </div>
      </div>
      <div class="form-row" v-if="show_payment_info">
        <div class="col-sm-6 col-12">
          <show-info-box title="Vencimento do boleto:">{{
            charge.payment_due_date | datetime_brl
          }}</show-info-box>
        </div>
        <div class="col-sm-6 col-12">
          <show-info-box title="Pagamento:">{{
            charge.payment_payment_date | datetime_brl
          }}</show-info-box>
        </div>
      </div>
      <title-box>Histórico</title-box>
      <history :charge_id="id" />
      <cancel-button cancel_text="Voltar" @cancel="goback" />
    </content-box>
  </div>
</template>
<script type="text/javascript">
import { mapActions, mapState } from 'vuex';
import CancelButton from '@/theme/buttons/CancelButton.vue';
import ContentBox from '@/theme/boxes/ContentBox.vue';
import Spinner from '@/features/Spinner/Spinner.vue';
import TitleBox from '@/theme/boxes/TitleBox.vue';
import ShowInfoBox from '@/theme/boxes/ShowInfoBox.vue';
import StatCard from '@/theme/boxes/StatCard.vue';
import History from '@/pages/history/pages/HistoryOnCharge.vue';
import filters from './filters';

export default {
  components: {
    CancelButton,
    ContentBox,
    Spinner,
    TitleBox,
    ShowInfoBox,
    StatCard,
    History,
  },
  data() {
    return {
      id: 0,
      processing: false,
    };
  },
  filters,
  methods: {
    ...mapActions('charges', ['ActionGetCharge']),
    async getCharge(id) {
      await this.ActionGetCharge({ id });
    },
    goback() {
      this.$router.back();
    },
  },
  computed: {
    ...mapState('charges', ['charge']),
    show_payment_info() {
      return this.charge.charge_status !== 'abandoned_cart';
    },
  },
  async mounted() {
    this.processing = true;
    this.id = this.$route.params.id;
    await this.getCharge(this.id);
    this.processing = false;
  },
};
</script>
<style type="text/css" scoped></style>
