<template functional>
  <div>
    <div class="history_item_container">
      <div class="history_item_date_container">
        <span>
          {{ props.history_date | datetime_brl }}
        </span>
        <i class="fa fa-calendar-alt"></i>
      </div>
      <div class="history_item_text_container">
        <div class="history_item_text">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  props: ['history_date'],
};
</script>
<style type="text/css" scoped>
.show_on_pc .history_item_container {
  width: 100%;
  display: flex;
}
.show_on_pc .history_item_date_container {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid #ccc;
  position: relative;
}
.show_on_pc .history_item_date span {
  display: block;
  text-align: center;
  height: auto;
  width: auto;
}

.show_on_pc .history_item_text_container {
  padding: 20px;
  padding-left: 50px;
  width: 75%;
  position: relative;
}
.show_on_pc .history_item_text::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 45px;
  width: 0;
  z-index: 1;
  left: 35px;
  border: medium solid white;
  border-width: 20px 20px 20px 0px;
  border-color: transparent #eee transparent transparent;
  background-color: transparent;
}
.show_on_pc .history_item_text {
  padding: 20px;
  border-radius: 8px;
  background-color: #eee;
}
i {
  font-size: 1.2rem;
  color: white;
  background-color: var(--standard-color);
  display: inline-block;
  height: 40px;
  width: 40px;
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  right: -20px;
}
</style>
