<template>
  <div class="stat_card">
    <div :style="{ minHeight: minheight }">
      <span class="stat_card_title" v-html="title"></span>
      <span class="stat_card_value" v-html="value"></span>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    title: {
      type: String,
      default: 'Not defined',
    },
    value: {
      type: [String, Number],
      default: 'Not defined',
    },
    minheight: {
      type: String,
      default: '30px',
    },
  },
};
</script>
<style type="text/css">
.stat_card {
  width: 100%;
  min-height: 70px;
  padding: 0px;
  margin-bottom: 10px;
}
.stat_card > div {
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 10px;
}
.stat_card_title {
  display: block;
  text-align: center;
  font-weight: 300;
  color: #888;
  margin-bottom: 5px;
}
.stat_card_value {
  display: block;
  text-align: center;
  font-weight: 700;
  color: #333;
}
</style>
