export default {
  charge_status: (status) => {
    const statusIndicators = {
      order_pending: "<span class='badge color-orange-white'>Pagamento pendente</span>",
      order_approved: "<span class='badge color-green-white'>Pago</span>",
      order_overdue: "<span class='badge color-grey-white'>Boleto vencido</span>",
      closed_charge: "<span class='badge color-grey-white'>Cobrança encerrada</span>",
      order_refunded: "<span class='badge color-grey-white'>Pedido reembolsado</span>",
      abandoned_cart: "<span class='badge color-grey-white'>Carrinho abandonado</span>",
      credit_card_refused: "<span class='badge color-red-white'>Cartão recusado</span>",
      billet_cancelled: "<span class='badge color-red-white'>Pedido cancelado</span>",
      unknown: "<span class='badge color-red-white'>Status desconhecido</span>",
    };

    return statusIndicators[status] ?? statusIndicators.unknown;
  },
  cpf: (document) => {
    if (!document) return null;
    const rawNumber = (String('00000000000') + document).substr(-11);
    return `${rawNumber.substr(0, 3)}.${rawNumber.substr(3, 3)}.${rawNumber.substr(6, 3)}-${rawNumber.substr(9, 2)}`;
  },
  orderNumber: (order) => {
    if (!order) return null;
    return `${order}`;
  },
  phoneNumber: (parmPhone) => {
    let phone = parmPhone;
    if (!phone) return '';

    if (phone.length === 11) phone = `55${String(phone)}`;

    return `+${phone.substr(0, 2)}  `
      + `(${phone.substr(2, 2)}) ${phone.substr(4, 1)}-${phone.substr(5, 4)}-${phone.substr(9, 4)}`;
  },
  charge_origin: (origin) => {
    const origins = {
      integration: 'Integração',
      import: 'Importação',
    };
    return origins[origin] ?? 'Não identificada';
  },
};
