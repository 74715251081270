<template functional>
  <div class="show_box" :class="alignment">
    <span class="title">{{ props.title }}</span>
    <span class="info">
      <slot></slot>
    </span>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    title: {
      type: String,
      default: 'Informação',
    },
    alignment: {
      type: String,
      default: 'left',
    },
  },
};
</script>
<style type="text/css" scoped>
.show_box {
  padding: 10px 7px;
}
.title {
  display: block;
  margin-bottom: 5px;
  color: #444;
  font-weight: 500;
}
.info {
  display: block;
  color: #222;
  font-weight: 700;
}
</style>
