import { render, staticRenderFns } from "./Alert.vue?vue&type=template&id=b1edf344&scoped=true&functional=true&"
var script = {}
import style0 from "./Alert.vue?vue&type=style&index=0&id=b1edf344&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "b1edf344",
  null
  
)

export default component.exports